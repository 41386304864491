<template>
  <section class="rule" v-html="remark"></section>
</template>

<script>
import { registerInfo } from "@/api/coupon";
import 'quill/dist/quill.snow.css'
export default {
  components: {},
  data() {
    return {
      remark: "",
    };
  },
  mounted() {
    if(this.$route.query.id) {
      registerInfo({ ticketUuid: this.$route.query.id }).then((res) => {
        if (res.returnCode === 10000) {
          this.remark = res.data.activityRuleUrl;
        }
      });
      this.$store.commit("loading/HIDELOADING");
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.rule {
  width: 100%;
  height: 100%;
  padding: 24px 30px;
  text-align: left;
  font-size: 20px;
  color: #333;
  background: #fff;
  box-sizing: border-box;
  word-break: break-all;
  .mt-0 {
    margin-top: 0;
  }
}
h2 {
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}
p {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffd9b8;
  opacity: 0.7;
  line-height: 21px;
}
</style>

